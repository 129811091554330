<template>
  <!-- 融资申请管理 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
    <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div>
    </base-form>

    <div class="main-page-table__header">
      <base-button label="新增" @click="visible = true" icon="el-icon-plus" />
    </div>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
         data: tableData,
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"
        />
        <!-- 审核状态为空，撤回，返回修改可编辑 -->
        <icon-button
          @click="edit(scope.row)"
          content="制作"
          icon="iconfont iconbianji3"
          v-if="scope.row.reviewStatus==='40'||scope.row.reviewStatus==='50'||scope.row.reviewStatus==='0'"
        />
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="del(scope.row.keyId)"
          v-if="scope.row.reviewStatus==='0'"
        />
        <icon-button
          content="预览"
          icon="iconfont iconyulan"
          @click="preview(scope.row)"
          v-if="scope.row.fileMakeStatus==='30'&&(scope.row.reviewStatus!=='40' ||scope.row.reviewStatus!=='90')"
        />
        <!-- 审核中可撤回 -->
        <!-- <icon-button
          content="撤回"
          icon="iconfont iconchehui"
          @click="withdraw(scope.row)"
          v-if="scope.row.reviewStatus==='10'"
        /> -->
      </template>
    </base-table>
    <!-- 新增弹窗 -->
    <add  :visible.sync="visible" :productOptions='productOptions' :gysData='gysData'/>
    <!-- 预览 -->
    <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="count" />
  </div>
</template>
<script>
import add from './components/add.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import { supplierApi } from '@/api/companyApi'
import { productApi } from '@/api/productApi'
import { progressApi } from '@/api/businessApi'
import { getDictLists } from '@/filters/fromDict'
// import { progressApi } from '@/api/businessApi'
import PreView from '@/components/pre-view/pre-view.vue'

import Storage from '@/utils/storage'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BaseButton, add, PreView },
  data () {
    return {
      fold: false,
      visible: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
        // processStatus: 'ADMIT'
      },
      disabled: false,
      tableData: [],
      gysData: [],
      productOptions: [], // 融资产品下拉
      fileData: { fileId: '', fileFileSuffix: '' },
      count: 0
    }
  },
  computed: {
    api () {
      return financeAdmittanceApi
    },
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    // 文件制作状态
    fileMakeOptions () {
      return getDictLists('FILE_MAKE_STATUS')
    },
    // 审核状态
    reviewOptions () {
      return getDictLists('REVIEW_STATUS')
    }
  },
  mounted () {
    this.getProductList()
    this.handleFilter()
    this.gysList()
  },
  methods: {
    // 查看详情
    info (data) {
      this.$router.push({
        path: '/business/accessDocumentsMake',
        query: { businessId: data.keyId, type: 'details' }
      })
      Storage.setLocal('admittanceInfo', data)
    },
    // 融资产品
    getProductList () {
      productApi.getProductList().then(res => {
        this.productOptions = res.data.map(item => {
          return { keyId: item.keyId, productName: item.productName }
        })
      })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    },
    // 制作
    edit (data) {
      this.$router.push({
        path: '/business/accessDocumentsMake',
        query: { businessId: data.keyId, type: 'deit', gysId: data.gysId }
      })
      Storage.setLocal('admittanceInfo', data)
    },
    // 删除
    del (data) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        financeAdmittanceApi.delAdmittance({ keyId: data }).then(res => {
          if (res.code === '0') {
            this.handleFilter()
          }
        })
      }).catch(() => {

      })
    },

    // 撤回
    withdraw (row) {
      this.$stateConfirm({
        title: '提示',
        message: '确认撤回吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        progressApi.cancel({ businessId: row.keyId, processStatus: row.statusCode }).then(res => {
          if (res.success) {
            this.success('撤回成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    },
    // 预览
    preview (data) {
      if (data.fileId && data.fileName) {
        this.fileData.fileId = data.fileId
        this.fileData.fileFileSuffix = data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4)
        this.count++
      } else {
        this.warning('文件信息有误，稍后重试')
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
