import Rest from '@/utils/http/Rest'
import http from '@/utils/http'

export default class Product extends Rest {
  /**
     * 获取表格列表数据
     * @param params
     * @returns {AxiosPromise}
     */
  getProductList () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`
    })
  }

  /**
     * 获取资金方信息
     * @param params
     * @returns {AxiosPromise}
     */
  getFundsproviderList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getCapitalSideProductInfo`,
      params
    })
  }

  // 新增财务信息
  queryAuthCapital (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/queryAuthCapital`,
      data
    })
  }
}
